/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Button, Image, ColumnCover, ColumnDiv, Title } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Děti v akci "}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-9z2ajy --full pb--60 pt--60" name={"3pdsdl4cedu"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/13d18f67f9d04cdeb92340ecadd4c537_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/13d18f67f9d04cdeb92340ecadd4c537_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/13d18f67f9d04cdeb92340ecadd4c537_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/13d18f67f9d04cdeb92340ecadd4c537_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/13d18f67f9d04cdeb92340ecadd4c537_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/13d18f67f9d04cdeb92340ecadd4c537_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/13d18f67f9d04cdeb92340ecadd4c537_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/13d18f67f9d04cdeb92340ecadd4c537_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --right el--1 flex--center" columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":650}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--border4" style={{"paddingBottom":12,"paddingTop":15}} name={"hukjqjnjbaa"} layout={"l1"} border={"4"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":800,"marginBottom":9,"paddingTop":9}} columns={"1"}>
            
            <ColumnWrapper style={{"paddingTop":0}}>
              
              <Button className="btn-box btn-box--pbtn4 btn-box--shape5" content={"<span style=\"font-weight: bold;\">Staňte se i Vy letos Ježíškem dětem z dětských domovů.<br></span>"} url={"https://donio.cz/vanoce-detem-kterym-osud-nepral"} href={"https://donio.cz/vanoce-detem-kterym-osud-nepral"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1c52xjh --full pb--60 pt--60" name={"1nujn10rqtu"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/7c5f63f033724137a142e9ec19853533_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/7c5f63f033724137a142e9ec19853533_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/7c5f63f033724137a142e9ec19853533_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/7c5f63f033724137a142e9ec19853533_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/7c5f63f033724137a142e9ec19853533_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/7c5f63f033724137a142e9ec19853533_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/7c5f63f033724137a142e9ec19853533_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/7c5f63f033724137a142e9ec19853533_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column layout={"l30"} style={{"paddingTop":26,"paddingBottom":25}} name={"s8ms4l1nppk"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/c20ec2c9a4154be49ea2a572294fed45_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/17142/c20ec2c9a4154be49ea2a572294fed45_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/c20ec2c9a4154be49ea2a572294fed45_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/c20ec2c9a4154be49ea2a572294fed45_s=860x_.jpg 860w"} lightbox={false} use={"url"} href={"https://www.instagram.com/p/DCyQKD9IJTd/?igsh=cmJlc281dGZqbjdi&img_index=1"} url={"https://www.instagram.com/p/DCyQKD9IJTd/?igsh=cmJlc281dGZqbjdi&img_index=1"}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/fa35ea242c3444768a75a6347b694c00_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/17142/fa35ea242c3444768a75a6347b694c00_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/fa35ea242c3444768a75a6347b694c00_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/fa35ea242c3444768a75a6347b694c00_s=860x_.jpg 860w"} lightbox={false} use={"url"} href={"https://www.instagram.com/p/DCizZsDIPeb/?igsh=dW1qaHlweW4yeWdj&img_index=1"} url={"https://www.instagram.com/p/DCizZsDIPeb/?igsh=dW1qaHlweW4yeWdj&img_index=1"}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/8a104fe6c86c4f66a7424b752d8590e7_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/17142/8a104fe6c86c4f66a7424b752d8590e7_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/8a104fe6c86c4f66a7424b752d8590e7_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/8a104fe6c86c4f66a7424b752d8590e7_s=860x_.jpg 860w"} lightbox={false} use={"url"} href={"https://www.instagram.com/p/DCizT4mo2k4/?igsh=bWNrY3VnZml6NWl2&img_index=1"} url={"https://www.instagram.com/p/DCizT4mo2k4/?igsh=bWNrY3VnZml6NWl2&img_index=1"}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3 flex--center" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/cc416967b33f44a7842e7a8fb0f131aa_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/17142/cc416967b33f44a7842e7a8fb0f131aa_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/cc416967b33f44a7842e7a8fb0f131aa_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/cc416967b33f44a7842e7a8fb0f131aa_s=860x_.jpg 860w"} lightbox={false} use={"url"} href={"https://www.instagram.com/p/DCqhxK0o1JT/?igsh=MXE2Y3lsOGI2NGtqMA%3D%3D&img_index=1"} url={"https://www.instagram.com/p/DCqhxK0o1JT/?igsh=MXE2Y3lsOGI2NGtqMA%3D%3D&img_index=1"}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/88bf3984d907464bb8d966d06cffe165_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/17142/88bf3984d907464bb8d966d06cffe165_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/88bf3984d907464bb8d966d06cffe165_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/88bf3984d907464bb8d966d06cffe165_s=860x_.jpg 860w"} lightbox={false} use={"url"} href={"https://www.instagram.com/p/DCqhvfZoIxb/?igsh=YXIxMDlrZGoxMW5m&img_index=1"} url={"https://www.instagram.com/p/DCqhvfZoIxb/?igsh=YXIxMDlrZGoxMW5m&img_index=1"}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/72fc46c24f1e42dfb1d29ee6a202e120_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/17142/72fc46c24f1e42dfb1d29ee6a202e120_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/72fc46c24f1e42dfb1d29ee6a202e120_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/72fc46c24f1e42dfb1d29ee6a202e120_s=860x_.jpg 860w"} lightbox={false} use={"url"} href={"https://www.instagram.com/p/DCyQJB_ooWf/?igsh=MWozYTRkZGd1ZHN2Yg%3D%3D&img_index=1"} url={"https://www.instagram.com/p/DCyQJB_ooWf/?igsh=MWozYTRkZGd1ZHN2Yg%3D%3D&img_index=1"}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--border4 --center" style={{"paddingBottom":37,"paddingTop":33}} name={"q0leqom2vu"} border={"4"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--26" content={"<a href=\"https://ib.fio.cz/ib/transparent?a=2402063206\" target=\"_blank\"><span style=\"font-weight: bold;\">Vánoční účet<br></span>2402063206 / 2010</a><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}